<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <sub-title><span>{{i18nConst.MENU['PRIVACY_POLICY'][$store.state.country]}}</span></sub-title>
                <div class="board_list">
                    <div class="notice_list">
                        <div class="ruleContent" v-if="$store.state.country === i18nConst.KR" v-html="ruleContent"></div>
                        <div class="ruleContent" v-if="$store.state.country === i18nConst.JP" v-html="ruleContentjp"></div>
                        <div class="ruleContent" v-if="$store.state.country === i18nConst.EN" v-html="ruleContenten"></div>
                        <div class="ruleContent" v-if="$store.state.country === i18nConst.FRA" v-html="ruleContentfra"></div>
                        <div class="ruleContent" v-if="$store.state.country === i18nConst.SPA" v-html="ruleContentspa"></div>
                    </div>
                </div>

            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>

    </div>
</template>

<script>
    import sportsConst from "../../common/sportsConst";
    import {getBetRuleList, getNoticeList, getNoticeList2} from "../../network/userRequest";
    import TopbarComp from "../../components/TopbarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import RightBarComp from "../../components/RightBarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import FootComp from "../../components/FootComp";
    import SubTitle from "../../components/SubTitle";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import {postionMixin} from "../../common/mixin";
    import i18nConst from "../../common/i18nConst";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";


    export default {
        name: "RuleUserInfo",
        mixins:[postionMixin],
        components: {
            RightButtonsComp,
            UserInfoComp,
            SubTitle, FootComp, RightBarBannerComp, RightBarComp, SportsLeftBarComp, LeftBarComp, TopbarComp},
        data(){
            return {
                sportsConst,
                ruleContent:null,
                ruleContentjp:null,
                ruleContenten:null,
                ruleContentfra:null,
                ruleContentspa:null,
                position:i18nConst.MENU['PRIVACY_POLICY'][this.$store.state.country],
                i18nConst:i18nConst,
            }
        },
        methods:{

        },
        created() {
            this.$store.commit(RECEIVE_SHOW_LOADING)
            getNoticeList2(sportsConst.BOARD_TYPE_RULE_USERINFO).then(res=>{
                if (res.data.success) {
                    this.ruleContent = res.data.data.content
                    this.ruleContentjp = res.data.data.contentJp
                    this.ruleContenten = res.data.data.contentEn
                    this.ruleContentspa = res.data.data.contentSpa
                    this.ruleContentfra = res.data.data.contentFra
                }
                this.$store.commit(RECEIVE_HIDE_LOADING)
            })
        }
    }
</script>

<style scoped>
    .ruleContent {
        box-sizing: border-box;
        padding: 20px;
        background-color: #cfcfd1;
    }
</style>